import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import pluralize from "pluralize";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplyIcon from "@material-ui/icons/Reply";
import SaveIcon from "@material-ui/icons/Save";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import {TabContext, TabPanel} from "@material-ui/lab";
import moment from "moment";
import {Switches as FinalSwitches} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import {Currency, Percentage} from "../../components/Accounting";
import {ConfirmationDialog} from "../../components/Dialogs";
import {
  BonusAccountAutoselectMui,
  CurrencyFieldMui,
  PercentFieldMui,
  TextFieldMui,
  UserAutoselectMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import InnerFooter from "../../components/InnerFooter";
import {AuditLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import {SuperuserInfo} from "../../components/SuperuserInfo";
import {TabCountChip} from "../../components/Tabs";
import Typography2 from "../../components/Typography2";
import usePermissions from "../../hooks/usePermissions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";

export const BonusAccountDialogForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      onDelete?: () => void;
      isOpen: boolean;
      isNew?: boolean;
      handleClose: () => void;
      [rest: string]: any;
    },
    ref: any
  ) => {
    const {onSubmit, onDelete, isOpen, handleClose, isNew = false, ...rest} = props;
    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                <DialogTitle id="form-dialog-title">{isNew ? "Create Account" : "Update Account"}</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <UserAutoselectMui
                            name="user"
                            label="User"
                            required
                            autoFocus
                            onChange={(event, value, reason) => {
                              if (reason === "clear") return;
                              form.batch(() => {
                                form.change("description", value.full_name);
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="description" label="Description" required />
                        </Grid>
                        <Grid item xs={12}>
                          <PercentFieldMui
                            name="default_bonus_percent"
                            label="Default Bonus Percent"
                            decimalScale={3}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FinalSwitches name="is_active" data={{label: "Active", value: true}} />
                          {/* <FormHelperText>Is Active</FormHelperText>s */}
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    label="Save"
                    disabled={pristine}
                    onClick={() => form.change("submitMode", "saveAndClose")}
                  />
                  {isNew && (
                    <FormActions.SaveAndEditButton
                      disabled={submitting || pristine}
                      onClick={() => form.change("submitMode", "editAfterSubmit")}
                    />
                  )}
                  {isNew && (
                    <FormActions.SaveAndAddButton
                      disabled={pristine}
                      onClick={() => form.change("submitMode", "addAnother")}
                    />
                  )}
                  {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const BonusAccountTransactionDialogForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      onDelete?: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      handleClose: () => void;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, onDelete, isOpen, handleClose, isNew = false, isReadOnly, ...rest} = props;
    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              // @ts-ignore
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                <DialogTitle id="form-dialog-title">{isNew ? "Create Transaction" : "Update Transaction"}</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="description"
                            label="Description"
                            disabled={isReadOnly}
                            required
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CurrencyFieldMui name="amount" label="Amount" required disabled={isReadOnly} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="notes" label="Notes" multiline minRows={2} disabled={isReadOnly} />
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                  {!isNew && (
                    <SuperuserInfo
                      objects={[
                        {
                          contentType: "bonuses",
                          model: "bonusaccounttransaction",
                          id: values.id,
                          title: values.display,
                        },
                      ]}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    label="Save"
                    disabled={pristine}
                    onClick={() => form.change("submitMode", "saveAndClose")}
                  />
                  {isNew && (
                    <FormActions.SaveAndAddButton
                      disabled={pristine}
                      onClick={() => form.change("submitMode", "addAnother")}
                    />
                  )}
                  {!isNew && !isReadOnly && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const ProjectBonusTransactionDialogForm = React.forwardRef(
  (
    props: {
      project: any;
      profit: number;
      onSubmit: (values: any) => void;
      onDelete?: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      handleClose: () => void;
      [rest: string]: any;
    },
    ref
  ) => {
    const {project, profit, onSubmit, onDelete, isOpen, handleClose, isNew = false, isReadOnly, ...rest} = props;
    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => {
              const defaultPercent =
                values?.account?.default_bonus_percent === undefined ? 0 : values?.account?.default_bonus_percent;
              const percentDiff = Math.abs(defaultPercent - values.data.percent);
              const projectProfitDiff = Math.abs(profit - values.data.profit);
              const calculatedAmount = values.data.profit * (values.data.percent / 100);
              const amountDiff = Math.abs(calculatedAmount - values.amount);
              return (
                // @ts-ignore
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="form-dialog-title">
                    {isNew ? "Create Project Bonus Transaction" : "Update Project Bonus Transaction"}
                  </DialogTitle>
                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <PaperPanel.Body>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <BonusAccountAutoselectMui
                              name="account"
                              label="Account"
                              autoFocus={isNew}
                              disabled={isReadOnly}
                              required
                              onChange={(event, value, reason) => {
                                if (reason === "clear") return;
                                form.batch(() => {
                                  form.change("description", `${project.display} - ${value.description}`);
                                  form.change("data.percent", value.default_bonus_percent);
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui
                              name="description"
                              label="Description"
                              disabled={isReadOnly}
                              required
                              autoFocus={!isNew}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <PercentFieldMui
                              name="data.percent"
                              label="Percent"
                              decimalScale={3}
                              disabled={isReadOnly}
                              onChange={(event) => {
                                form.change("data.percent", event.target.value);
                                form.batch(() => {
                                  const amount = values.data.profit * (event.target.value / 100);
                                  form.change("amount", Math.round(amount));
                                });
                              }}
                            />
                            {percentDiff > 0.001 && (
                              <FormHelperText>
                                Does not match account default percent{" "}
                                <Percentage number={defaultPercent / 100} precision={3} />.
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <CurrencyFieldMui
                              name="data.profit"
                              label="Profit"
                              required
                              disabled={isReadOnly}
                              onChange={(event) => {
                                form.change("data.profit", event.target.value);
                                form.batch(() => {
                                  const amount = event.target.value * (values.data.percent / 100);
                                  form.change("amount", Math.round(amount));
                                });
                              }}
                            />
                            {projectProfitDiff > 1 && (
                              <FormHelperText>
                                Not equal to the project profit <Currency number={profit} precision={0} />
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <CurrencyFieldMui name="amount" label="Amount" required disabled={isReadOnly} />
                            {amountDiff > 1 && (
                              <FormHelperText>
                                Calculated amount based on % and profit does not match{" "}
                                <Currency number={calculatedAmount} precision={0} />
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name="notes" label="Notes" multiline minRows={2} disabled={isReadOnly} />
                          </Grid>
                        </Grid>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </PaperPanel.Body>
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton label="Save" disabled={pristine} />
                    {!isNew && !isReadOnly && <FormActions.DeleteButton onClick={() => onDelete()} />}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const BonusAccountCloseoutRequestDialogForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      onDelete?: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      handleClose: () => void;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, onDelete, isOpen, handleClose, isNew = false, isReadOnly = false, ...rest} = props;
    const [confirmSubmit, setConfirmSubmit] = React.useState(false);
    const [confirmApprove, setConfirmApprove] = React.useState(false);
    const [confirmReject, setConfirmReject] = React.useState(false);

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              // @ts-ignore
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                <DialogTitle id="form-dialog-title">
                  {!isNew && <StatusLabel status={values.status_display} hint="bonusAccountDistributionRequest" />}{" "}
                  {isNew ? "Create Closeout Request" : "Update Closeout Request"}
                </DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="description"
                            label="Description"
                            autoFocus
                            required
                            disabled={isReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="notes" label="Notes" multiline minRows={2} disabled={isReadOnly} />
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton label="Save" disabled={pristine && !isNew} />
                  {!isNew && !values.is_closed && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
                <ConfirmationDialog
                  isOpen={confirmSubmit}
                  onApprove={() => {
                    setConfirmSubmit(false);
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setConfirmSubmit(false);
                  }}
                >
                  You would like to submit this distribution request for <Currency number={values.amount} />?
                </ConfirmationDialog>
                <ConfirmationDialog
                  isOpen={confirmApprove}
                  onApprove={() => {
                    setConfirmApprove(false);
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setConfirmApprove(false);
                  }}
                >
                  You would like to approve this distribution request for <Currency number={values.amount} />?
                </ConfirmationDialog>
                <ConfirmationDialog
                  isOpen={confirmReject}
                  onApprove={() => {
                    setConfirmReject(false);
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setConfirmReject(false);
                  }}
                >
                  You would like to reject this distribution request for <Currency number={values.amount} />?
                </ConfirmationDialog>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

const BonusAccountCloseoutRequestItemCommentForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      onDelete?: (values: any) => void;
      inNew?: boolean;
      isReadOnly?: boolean;
      [rest: string]: any;
    },
    ref: any
  ) => {
    const {onSubmit, onDelete, isNew, isReadOnly = false, ...rest} = props;

    return (
      <>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine && !isNew}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name="comment"
                        label="Comment"
                        required
                        disabled={isReadOnly}
                        multiline
                        minRows={1}
                        helperText={
                          !isNew ? `${values.created_by.full_name} - ${moment(values.created).format("LLLL")}` : null
                        }
                        onBlur={() => {
                          if (!isNew && !pristine) {
                            form.submit();
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <Tooltip title={isNew ? "Create Comment" : "Update Comment"}>
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    disabled={submitting || pristine}
                                    onClick={() => {
                                      form.submit();
                                    }}
                                  >
                                    {isNew ? <AddIcon /> : <SaveIcon />}
                                  </IconButton>
                                </InputAdornment>
                              </Tooltip>
                              {!isNew && (
                                <Tooltip title="Delete Comment">
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        onDelete(values);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </Tooltip>
                              )}
                            </>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </form>
            );
          }}
        </FinalForm>
      </>
    );
  }
);

export const BonusAccountCloseoutRequestItemDialogForm = React.forwardRef(
  (
    props: {
      bonusAccountId: number;
      closeoutRequestId: number;
      closeoutRequestItemId?: number;
      initialValues: any;
      onSubmit: (values: any) => void;
      onDelete?: (values: any) => void;
      isNew: boolean;
      isOpen: boolean;
      isReadOnly?: boolean;
      showAuditLink?: boolean;
      handleClose: () => void;
      defaultSFGrossReceiptsTaxRate?: number;
      defaultOverheadAndInsuranceRate?: number;
      [rest: string]: any;
    },
    ref: any
  ) => {
    const {
      bonusAccountId,
      closeoutRequestId,
      closeoutRequestItemId,
      initialValues,
      onSubmit,
      onDelete,
      isOpen,
      handleClose,
      isNew = false,
      isReadOnly = false,
      showAuditLink = false,
      account,
      defaultSFGrossReceiptsTaxRate,
      defaultOverheadAndInsuranceRate,

      ...rest
    } = props;

    const showComments = !isNew && closeoutRequestItemId;

    const [selectedTab, setSelectedTab] = React.useState("detail");
    const [deleteCommentConfirmationIsOpen, setDeleteCommentConfirmationIsOpen] = React.useState(false);
    const [activeComment, setActiveComment] = React.useState({} as any);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const permissions = usePermissions();
    const novoClasses = makeNovoClasses();
    const {
      query: commentQuery,
      create: createComment,
      update: updateComment,
      delete: deleteComment,
    } = useSentinelListAPI(
      `/bonuses/accounts/${bonusAccountId}/closeout-requests/${closeoutRequestId}/items/${closeoutRequestItemId}/comments/`,
      {
        initialData: {
          results: [],
        },
        enabled: Boolean(showComments),
      }
    );
    const comments = commentQuery.data.results;
    const commentCount = comments.length;

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">
            {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
            {isNew ? "Create Closeout Request Item" : initialValues?.project?.display}
          </DialogTitle>
          <TabContext value={selectedTab}>
            {showComments && (
              <DialogTitle id="tab-dialog-title">
                <Tabs
                  value={selectedTab}
                  onChange={(event, newValue) => {
                    setSelectedTab(newValue);
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab value="detail" className={novoClasses.smallTab} label="Detail" />
                  <Tab
                    value="comments"
                    className={novoClasses.smallTab}
                    label={
                      <Box display="flex" alignItems="center">
                        Comments
                        <TabCountChip isLoading={!commentQuery.isFetchedAfterMount} count={commentQuery.data.count} />
                      </Box>
                    }
                  />
                </Tabs>
              </DialogTitle>
            )}
            <TabPanel value="detail">
              <Box m={-3}>
                <FinalForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
                  {({handleSubmit, form, submitting, pristine, values}) => {
                    const bonusPercent = (values.bonus_percent || 0) / 100;
                    const totalProfit =
                      (values.gross_profit || 0) -
                      (values.sf_gross_receipts_tax || 0) -
                      (values.overhead_and_insurance || 0) -
                      (values.cost_adjustments || 0);
                    const bonusAmount = bonusPercent * totalProfit;
                    const sfGrossReceiptsTaxRate =
                      values?.data?.project_data?.sf_gross_receipts_tax_percent || defaultSFGrossReceiptsTaxRate;
                    const overheadAndInsuranceRate =
                      values?.data?.project_data?.overhead_and_insurance_percent || defaultOverheadAndInsuranceRate;

                    return (
                      <>
                        <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                          <DialogContent>
                            <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine && !isNew}>
                              <PaperPanel.Body>
                                <Table size="small" padding="none">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Final Billing</TableCell>
                                      <TableCell align="right">
                                        <Currency number={isNew ? values.jtd_work_billed : values.final_billing} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>JTD Cost</TableCell>
                                      <TableCell align="right">
                                        <Currency number={values.jtd_cost} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Gross Profit</TableCell>
                                      <TableCell align="right">
                                        <Currency number={values.gross_profit} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        SF Gross Receipts Tax
                                        {sfGrossReceiptsTaxRate && values.sf_gross_receipts_tax !== 0 && (
                                          <Typography2 type="metadata">
                                            {" "}
                                            -{" "}
                                            <Currency
                                              number={isNew ? values.jtd_work_billed : values.final_billing}
                                            />{" "}
                                            * <Percentage number={sfGrossReceiptsTaxRate} precision={2} />
                                          </Typography2>
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        <Currency number={values.sf_gross_receipts_tax} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        Overhead and Insurance
                                        {overheadAndInsuranceRate && values.overhead_and_insurance !== 0 && (
                                          <Typography2 type="metadata">
                                            {" "}
                                            -{" "}
                                            <Currency
                                              number={isNew ? values.jtd_work_billed : values.final_billing}
                                            />{" "}
                                            * <Percentage number={overheadAndInsuranceRate} precision={2} />
                                          </Typography2>
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        <Currency number={values.overhead_and_insurance} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Cost Adjustments</TableCell>
                                      <TableCell align="right">
                                        <Currency number={values.cost_adjustments} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Profit</TableCell>
                                      <TableCell align="right">
                                        <Currency number={totalProfit} />
                                      </TableCell>
                                    </TableRow>
                                    {isNew && (
                                      <TableRow>
                                        <TableCell>Profit %</TableCell>
                                        <TableCell align="right">
                                          <Percentage number={values.gross_profit_percent} precision={2} />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                                <Box mb={2} />
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <PercentFieldMui
                                      name="bonus_percent"
                                      label="Bonus %"
                                      decimalScale={2}
                                      disabled={
                                        !permissions.can_manage_bonuses ||
                                        values.is_closed ||
                                        values.is_bonus_percent_locked
                                      }
                                      helperText={
                                        values.is_bonus_percent_locked
                                          ? "Bonus percent is locked at project level."
                                          : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <CurrencyFieldMui
                                      name="cost_adjustments"
                                      label="Cost Adjustments"
                                      disabled={isReadOnly}
                                      helperText="Negative value will decrease cost and increase profit/bonus. Positive value will increase cost and decrease profit/bonus."
                                      required
                                    />
                                  </Grid>
                                  {isNew && (
                                    <Grid item xs={12}>
                                      <TextFieldMui
                                        name="comment"
                                        label="Comment"
                                        multiline
                                        minRows={2}
                                        disabled={isReadOnly}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <h3>
                                  Total Bonus: <Currency number={bonusAmount} />
                                </h3>
                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                              </PaperPanel.Body>
                            </FinalFormKeyboardShortcuts>
                            {!isNew && showAuditLink && (
                              <AuditLink app="bonuses" model="bonusaccountcloseoutrequestitem" id={values.id} mt={1} />
                            )}
                            {!isNew && (
                              <SuperuserInfo
                                objects={[
                                  {
                                    contentType: "bonuses",
                                    model: "bonusaccountcloseoutrequestitem",
                                    id: values.id,
                                    title: values.display,
                                  },
                                ]}
                              />
                            )}
                          </DialogContent>
                          <DialogActions>
                            {isNew ? (
                              <FormActions.SaveButton label="Add" />
                            ) : (
                              <FormActions.SaveButton disabled={pristine} />
                            )}
                            {!isNew && !isReadOnly && (
                              <FormActions.DeleteButton onClick={() => setConfirmDelete(true)} />
                            )}
                            <FormActions.CancelButton onClick={handleClose} />
                          </DialogActions>
                        </form>
                        <ConfirmationDialog
                          isOpen={confirmDelete}
                          onApprove={() => {
                            setConfirmDelete(false);
                            onDelete(values);
                          }}
                          onDeny={() => {
                            setConfirmDelete(false);
                          }}
                        >
                          You would like to remove this item form the closeout request?
                          {commentCount > 0 && (
                            <Box>
                              This will permanently delete the {commentCount} {pluralize("comments", commentCount)}{" "}
                              associated with this item.
                            </Box>
                          )}
                        </ConfirmationDialog>
                      </>
                    );
                  }}
                </FinalForm>
              </Box>
            </TabPanel>
            <TabPanel value="comments">
              <Box m={-3}>
                <DialogContent>
                  <List>
                    {comments.map((comment) => {
                      if (comment.is_closed) {
                        return (
                          <Box key={comment.id} mb={1} data-id={comment.id}>
                            <Paper key={comment.id} elevation={3}>
                              <ListItem>
                                <ListItemText
                                  primary={<Typography variant="body1">{comment.comment}</Typography>}
                                  secondary={
                                    <Typography variant="caption" color="textSecondary">
                                      {comment.created_by?.full_name} - {moment(comment.created).format("LLLL")}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </Paper>
                          </Box>
                        );
                      } else {
                        return (
                          <Box key={comment.id} data-id={comment.id}>
                            <BonusAccountCloseoutRequestItemCommentForm
                              onSubmit={(values) => {
                                updateComment.mutateAsync(values).then(() => {
                                  commentQuery.refetch();
                                });
                              }}
                              isReadOnly={comment.is_closed}
                              initialValues={comment}
                              onDelete={() => {
                                setActiveComment(comment);
                                setDeleteCommentConfirmationIsOpen(true);
                              }}
                            />
                          </Box>
                        );
                      }
                    })}
                    {!isReadOnly && (
                      <>
                        <Box mt={2} />
                        <BonusAccountCloseoutRequestItemCommentForm
                          onSubmit={(values, form) => {
                            // console.log("form", form);
                            createComment.mutateAsync(values).then(() => {
                              commentQuery.refetch();
                              form.restart();
                            });
                          }}
                          isNew
                        />
                      </>
                    )}
                  </List>
                  <DialogActions>
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </DialogContent>
              </Box>
              <ConfirmationDialog
                isOpen={deleteCommentConfirmationIsOpen}
                onApprove={() => {
                  setDeleteCommentConfirmationIsOpen(false);
                  deleteComment.mutateAsync(activeComment.id);
                }}
                onDeny={() => {
                  setDeleteCommentConfirmationIsOpen(false);
                }}
              >
                You want to delete this comment? This cannot be undone.
              </ConfirmationDialog>
            </TabPanel>
          </TabContext>
        </Dialog>
      </>
    );
  }
);

export const BonusAccountDistributionRequestDialogForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      onDelete?: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      handleClose: () => void;
      account?: any;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, onDelete, isOpen, handleClose, isNew = false, isReadOnly = false, account, ...rest} = props;
    const [confirmSubmit, setConfirmSubmit] = React.useState(false);
    const [confirmApprove, setConfirmApprove] = React.useState(false);
    const [confirmReject, setConfirmReject] = React.useState(false);

    const permissions = usePermissions();

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              // @ts-ignore
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                <DialogTitle id="form-dialog-title">
                  {!isNew && <StatusLabel status={values.status_display} hint="bonusAccountDistributionRequest" />}{" "}
                  {isNew ? "Create Distribution Request" : "Update Distribution Request"}
                </DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {account && (
                            <>
                              Account balance: <Currency number={account.amount} />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="description"
                            label="Description"
                            required
                            autoFocus
                            disabled={isReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CurrencyFieldMui
                            name="amount"
                            label="Amount"
                            required
                            disabled={isReadOnly}
                            fieldProps={{
                              validate: (value) => {
                                console.log("value", value);
                                if (value === undefined || value === null) {
                                  return "Distribution request amount is required";
                                }
                                if (value <= 0) {
                                  return "Distribution request amount must be greater than 0";
                                }
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="notes" label="Notes" multiline minRows={2} disabled={isReadOnly} />
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                  {!isNew && (
                    <SuperuserInfo
                      objects={[
                        {
                          contentType: "bonuses",
                          model: "bonusaccountdistributionrequest",
                          id: values.id,
                          title: values.display,
                        },
                      ]}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton label="Save" disabled={pristine} />
                  {(isNew || values.can_submit) && (
                    <FormActions.ColoredButton
                      type="button"
                      label={values.status === "rejected" ? "Save & Resubmit" : "Save & Submit"}
                      startIcon={<ReplyIcon />}
                      color="success"
                      onClick={() => {
                        form.change("submitMode", "submit");
                        setConfirmSubmit(true);
                      }}
                    />
                  )}
                  {values.can_approve && permissions.can_manage_bonuses && (
                    <FormActions.ColoredButton
                      label="Save & Approve"
                      startIcon={<ThumbUpIcon />}
                      color="success"
                      onClick={() => {
                        form.change("submitMode", "approve");
                        setConfirmApprove(true);
                      }}
                    />
                  )}
                  {values.can_reject && permissions.can_manage_bonuses && (
                    <FormActions.ColoredButton
                      label="Save & Reject"
                      startIcon={<ThumbDownIcon />}
                      color="error"
                      onClick={() => {
                        form.change("submitMode", "reject");
                        setConfirmReject(true);
                      }}
                    />
                  )}
                  {!isNew && !values.is_closed && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
                <ConfirmationDialog
                  isOpen={confirmSubmit}
                  onApprove={() => {
                    setConfirmSubmit(false);
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setConfirmSubmit(false);
                  }}
                >
                  You would like to submit this distribution request for <Currency number={values.amount} />?
                </ConfirmationDialog>
                <ConfirmationDialog
                  isOpen={confirmApprove}
                  onApprove={() => {
                    setConfirmApprove(false);
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setConfirmApprove(false);
                  }}
                >
                  You would like to approve this distribution request for <Currency number={values.amount} />?
                </ConfirmationDialog>
                <ConfirmationDialog
                  isOpen={confirmReject}
                  onApprove={() => {
                    setConfirmReject(false);
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setConfirmReject(false);
                  }}
                >
                  You would like to reject this distribution request for <Currency number={values.amount} />?
                </ConfirmationDialog>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const BonusAccountDistributionRequestForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      onDelete?: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      handleClose: () => void;
      account?: any;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, onDelete, isOpen, handleClose, isNew = false, isReadOnly = false, account, ...rest} = props;
    const [confirmSubmit, setConfirmSubmit] = React.useState(false);
    const [confirmApprove, setConfirmApprove] = React.useState(false);
    const [confirmReject, setConfirmReject] = React.useState(false);

    const permissions = usePermissions();

    return (
      <>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            // @ts-ignore
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {account && (
                      <>
                        Account balance: <Currency number={account.amount} />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="description" label="Description" required autoFocus disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <CurrencyFieldMui name="amount" label="Amount" required disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="notes" label="Notes" multiline minRows={2} disabled={isReadOnly} />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </FinalFormKeyboardShortcuts>

              <InnerFooter>
                <Box display="flex">
                  <FormActions.SaveButton label="Save" disabled={pristine} />
                  {(isNew || values.can_submit) && (
                    <>
                      <Box ml={1} />
                      <FormActions.ColoredButton
                        type="button"
                        label={values.status === "rejected" ? "Save & Resubmit" : "Save & Submit"}
                        startIcon={<ReplyIcon />}
                        color="success"
                        onClick={() => {
                          form.change("submitMode", "submit");
                          setConfirmSubmit(true);
                        }}
                      />{" "}
                    </>
                  )}
                  {values.can_approve && permissions.can_manage_bonuses && (
                    <>
                      <Box ml={1} />
                      <FormActions.ColoredButton
                        label="Save & Approve"
                        startIcon={<ThumbUpIcon />}
                        color="success"
                        onClick={() => {
                          form.change("submitMode", "approve");
                          setConfirmApprove(true);
                        }}
                      />
                    </>
                  )}
                  {values.can_reject && permissions.can_manage_bonuses && (
                    <>
                      <Box ml={1} />
                      <FormActions.ColoredButton
                        label="Save & Reject"
                        startIcon={<ThumbDownIcon />}
                        color="error"
                        onClick={() => {
                          form.change("submitMode", "reject");
                          setConfirmReject(true);
                        }}
                      />
                    </>
                  )}
                </Box>
              </InnerFooter>
              <ConfirmationDialog
                isOpen={confirmSubmit}
                onApprove={() => {
                  setConfirmSubmit(false);
                  onSubmit(values);
                }}
                onDeny={() => {
                  setConfirmSubmit(false);
                }}
              >
                You would like to submit this distribution request for <Currency number={values.amount} />?
              </ConfirmationDialog>
              <ConfirmationDialog
                isOpen={confirmApprove}
                onApprove={() => {
                  setConfirmApprove(false);
                  onSubmit(values);
                }}
                onDeny={() => {
                  setConfirmApprove(false);
                }}
              >
                You would like to approve this distribution request for <Currency number={values.amount} />?
              </ConfirmationDialog>
              <ConfirmationDialog
                isOpen={confirmReject}
                onApprove={() => {
                  setConfirmReject(false);
                  onSubmit(values);
                }}
                onDeny={() => {
                  setConfirmReject(false);
                }}
              >
                You would like to reject this distribution request for <Currency number={values.amount} />?
              </ConfirmationDialog>
            </form>
          )}
        </FinalForm>
      </>
    );
  }
);

export const ProjectBonusPercentDialogForm = React.forwardRef(
  (
    props: {
      projectId: number;
      onSubmit: (values: any) => void;
      onDelete?: (values: any) => void;
      isNew: boolean;
      isOpen: boolean;
      isReadOnly?: boolean;
      handleClose: () => void;
      [rest: string]: any;
    },
    ref
  ) => {
    const {
      projectId,
      onSubmit,
      onDelete,
      isOpen,
      handleClose,
      isNew = false,
      isReadOnly = false,
      account,
      ...rest
    } = props;

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => {
              const validateBonusPercent = async (value, values, field) => {
                if (!value) return "Required";
                let url = `/projects/${projectId}/bonuses/percents/validate-total-percent/?bonus_percent=${value}`;
                if (!isNew) {
                  url += `&exclude_id=${values.id}`;
                }
                const response = await axiosAPI.get(url);
                if (response.data.error) {
                  return response.data.error;
                }
              };

              return (
                // @ts-ignore
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="form-dialog-title">
                    {isNew ? "Create Project Bonus Percent" : "Update Project Bonus Percent"}
                  </DialogTitle>

                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <PaperPanel.Body>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <PercentFieldMui
                              name="bonus_percent"
                              label="Bonus %"
                              decimalScale={2}
                              disabled={isReadOnly}
                              required
                              autoFocus
                              fieldProps={{
                                validate: validateBonusPercent,
                              }}
                            />
                          </Grid>
                        </Grid>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>s */}
                      </PaperPanel.Body>
                      {!isNew && (
                        <SuperuserInfo
                          objects={[
                            {
                              contentType: "bonuses",
                              model: "projectbonuspercent",
                              id: values.id,
                              title: values.account.user.full_name,
                            },
                          ]}
                        />
                      )}
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton label={isNew ? "Add" : undefined} disabled={isReadOnly || pristine} />
                    {!isNew && !isReadOnly && <FormActions.DeleteButton onClick={() => onDelete(values)} />}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);
