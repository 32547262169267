import {faEdit, faPlus, faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Link, Switch, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import {TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {Currency, Percentage} from "../../components/Accounting";
import {ConfirmationDialog} from "../../components/Dialogs";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useLocalStorageWithExpiration} from "../../hooks/useLocalStorage";
import usePermissions from "../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {BonusAccountCloseoutRequestDialogForm, BonusAccountCloseoutRequestItemDialogForm} from "./BonusForms";
import Typography2 from "../../components/Typography2";

const BonusAccountCloseoutRequestDetail = (props) => {
  const [selectedCloseoutRequestItem, setSelectedCloseoutRequestItem] = React.useState({} as any);
  const [selectedProjectToBeClosed, setSelectedProjectToBeClosed] = React.useState({} as any);
  const [showUpdateCloseoutRequestDialog, setShowUpdateCloseoutRequestDialog] = React.useState(false);
  const [showCreateCloseoutRequestItemDialogForm, setShowCreateCloseoutRequestItemDialogForm] = React.useState(false);
  const [showUpdateCloseoutRequestItemDialogForm, setShowUpdateCloseoutRequestItemDialogForm] = React.useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = React.useState(false);
  const [showManagementApproveDialog, setShowManagementApproveDialog] = React.useState(false);
  const [showFinalApproveDialog, setShowFinalApproveDialog] = React.useState(false);
  const [showRejectDialog, setShowRejectDialog] = React.useState(false);
  const [selectedTab, setSelectedTab] = useQueryState("tab", "detail");
  const {bonusAccountId, closeoutRequestId} = useParams();

  const permissions = usePermissions();

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const navigate = useNavigate();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      projectsForCloseout: [],
      projectBonusPercents: {},
    },
  });
  const pageData = pageDataQuery.data;
  // const {projectsForCloseout, projectBonusPercents} = pageData;

  const {query: bonusAccountQuery} = useSentinelDetailAPI(["bonuses", "accounts", parseInt(bonusAccountId)], {
    initialData: {},
  });
  const bonusAccount = bonusAccountQuery.data;

  const {
    query: closeoutRequestQuery,
    update: updateCloseoutRequest,
    delete: deleteCloseoutRequest,
    rpc: closeoutRequestRPC,
  } = useSentinelDetailAPI(`bonuses/accounts/${bonusAccountId}/closeout-requests/${closeoutRequestId}/`, {
    initialData: {},
  });
  const closeoutRequest = closeoutRequestQuery.data;

  const {
    query: closeoutRequestItemsQuery,
    create: createCloseoutRequestItem,
    update: updateCloseoutRequestItem,
    delete: deleteCloseoutRequestItem,
    rpc: closeoutRequestItemRPC,
  } = useSentinelListAPI(`bonuses/accounts/${bonusAccountId}/closeout-requests/${closeoutRequestId}/items/`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  const closeoutRequestItems = closeoutRequestItemsQuery.data.results;

  const {query: projectsForCloseoutQuery} = useSentinelListAPI(
    `bonuses/accounts/${bonusAccountId}/closeout-requests/projects-for-closeout/`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const projectsForCloseout = projectsForCloseoutQuery.data.results;

  if (
    !pageDataQuery.isFetchedAfterMount ||
    !closeoutRequestQuery.isFetchedAfterMount ||
    !bonusAccountQuery.isFetchedAfterMount
  ) {
    return <BlockUI show={true} message="Fetching Closeout Request info..." />;
  }

  return (
    <>
      <Helmet title={`Closeout Request ${closeoutRequest.display}`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to="/v2/bonuses/accounts/">
          Bonus Accounts
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/bonuses/accounts/${bonusAccountId}/`}>
          {bonusAccount.description}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/bonuses/accounts/${bonusAccountId}/#tab=closeoutRequests`}>
          Closeout Requests
        </MuiNavLink>
        <Typography color="textPrimary">{closeoutRequest.display}</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faSackDollar} /> {closeoutRequest.display}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark
            title="Total Bonus Amount"
            number={closeoutRequest.bonus_amount}
            precision={2}
          />
        </PageHeader.Right>
      </PageHeader>

      {/* <pre>{JSON.stringify(closeoutRequestItems, null, 2)}</pre> */}
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Detail" value="detail" className={novoClasses.smallTab} />
              <Tab label="Logs" value="logs" className={novoClasses.smallTab} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="detail">
            <PaperPanel.TabHeader isLoading={closeoutRequestItemsQuery.isFetching}>
              <PaperPanel.Header.Title>
                <StatusLabel status={closeoutRequest.status_display} hint="bonusAccountDistributionRequest" /> Closeout
                Items
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  {closeoutRequest.can_submit && (
                    <Box mr={1}>
                      <PaperPanel.Header.ColoredButton
                        startIcon={<ReplyIcon />}
                        color="success"
                        disabled={closeoutRequestItems.length === 0}
                        onClick={() => setShowSubmitDialog(true)}
                      >
                        {closeoutRequest.status === "rejected" ? "Resubmit" : "Submit"}
                      </PaperPanel.Header.ColoredButton>
                    </Box>
                  )}
                  {closeoutRequest.can_management_approve && permissions.can_manage_bonuses && (
                    <Box mr={1}>
                      <PaperPanel.Header.ColoredButton
                        startIcon={<ThumbUpIcon />}
                        color="success"
                        onClick={() => setShowManagementApproveDialog(true)}
                      >
                        Approve
                      </PaperPanel.Header.ColoredButton>
                    </Box>
                  )}
                  {closeoutRequest.can_approve && (
                    <Box mr={1}>
                      <PaperPanel.Header.ColoredButton
                        startIcon={<ThumbUpIcon />}
                        color="success"
                        onClick={() => setShowFinalApproveDialog(true)}
                      >
                        Approve
                      </PaperPanel.Header.ColoredButton>
                    </Box>
                  )}
                  {closeoutRequest.can_reject && permissions.can_manage_bonuses && (
                    <Box mr={1}>
                      <PaperPanel.Header.ColoredButton
                        startIcon={<ThumbDownIcon />}
                        color="error"
                        onClick={() => setShowRejectDialog(true)}
                      >
                        Reject
                      </PaperPanel.Header.ColoredButton>
                    </Box>
                  )}
                  <PaperPanel.Header.EditButton
                    onClick={() => setShowUpdateCloseoutRequestDialog(true)}
                    disabled={closeoutRequest.is_closed}
                  >
                    Edit
                  </PaperPanel.Header.EditButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={closeoutRequestItemsQuery.isFetching}
                    onClick={() => closeoutRequestItemsQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  rightAlignColumns={[
                    "final_billing",
                    "jtd_work_billed",
                    "jtd_cost",
                    "current_timberline_jtd_cost",
                    "sf_gross_receipts_tax",
                    "overhead_and_insurance",
                    "cost_adjustments",
                    "bonus_percent",
                    "net_profit",
                    "bonus_amount",
                  ]}
                  showFooter={Boolean(closeoutRequestItems.length)}
                  getHeaderProps={(column) => {
                    if (["edit"].includes(column.id)) {
                      return {
                        style: {
                          width: "20px",
                        },
                      };
                    }
                  }}
                  getCellProps={(cell) => {
                    if (["timberline_job"].includes(cell.column.id)) {
                      return {
                        style: {
                          whiteSpace: "nowrap",
                        },
                      };
                    }
                  }}
                  columns={[
                    {
                      Header: "",
                      id: "edit",
                      disableSortBy: true,
                      Cell: ({value, row}) => {
                        return (
                          <Tooltip title="Edit Closeout Request Item">
                            <FontAwesomeIcon
                              icon={faEdit}
                              fixedWidth
                              onClick={() => {
                                setSelectedCloseoutRequestItem(row.original);
                                setShowUpdateCloseoutRequestItemDialogForm(true);
                              }}
                              style={{cursor: "pointer"}}
                            />
                          </Tooltip>
                        );
                      },
                    },
                    {
                      Header: "Job #",
                      accessor: "project.display",

                      Cell: ({value, row}) => {
                        return (
                          <Link
                            href={`/v2/projects/${row.original.project.id}/bonuses/closeout-requests/`}
                            underline="always"
                          >
                            {value}
                          </Link>
                        );
                      },
                    },
                    {
                      Header: "Final Billing",
                      accessor: "final_billing",
                      Cell: ({value, row}) => <Currency number={value} />,
                    },
                    {
                      Header: "JTD Cost",
                      accessor: "jtd_cost",
                      Cell: ({value, row}) => <Currency number={value} />,
                    },
                    {
                      Header: "Current TL Cost *",
                      accessor: "current_timberline_jtd_cost",
                      Cell: ({value, row}) => (
                        <>
                          <Tooltip title={<Currency number={value - row.original.jtd_cost} />}>
                            <span style={{fontWeight: Math.abs(value - row.original.jtd_cost) > 1 ? "bold" : ""}}>
                              <Currency number={value} />
                            </span>
                          </Tooltip>
                        </>
                      ),
                    },
                    {
                      Header: "SF Tax",
                      accessor: "sf_gross_receipts_tax",
                      Cell: ({value, row}) => <Currency number={value} />,
                    },
                    {
                      Header: "Overhead & Insurance",
                      accessor: "overhead_and_insurance",
                      Cell: ({value, row}) => <Currency number={value} />,
                    },
                    {
                      Header: "Cost Adjustments",
                      accessor: "cost_adjustments",
                      Cell: ({value, row}) => (
                        <>
                          <Box>
                            <Currency number={value} />
                          </Box>
                        </>
                      ),
                    },
                    {
                      Header: "Profit",
                      accessor: "net_profit",
                      Cell: ({value, row}) => <Currency number={value} />,
                    },
                    {
                      Header: "Bonus %",
                      accessor: "bonus_percent",
                      Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                      Footer: () => {
                        const total = closeoutRequestItems.reduce((sum, row) => row.bonus_percent + sum, 0);
                        const count = closeoutRequestItemsQuery.data.count;
                        if (count === 0) return "";
                        const average = total / count;
                        return <Percentage number={average / 100} precision={2} />;
                      },
                    },
                    {
                      Header: "Bonus",
                      accessor: "bonus_amount",
                      Cell: ({value, row}) => <Currency number={value} />,
                      Footer: () => {
                        const total = closeoutRequestItems.reduce((sum, row) => row.bonus_amount + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                  ]}
                  initialState={{
                    // sortBy: [
                    //   {
                    //     id: "timberline_job",
                    //     desc: false,
                    //   },
                    // ],
                    hiddenColumns: !permissions.can_manage_bonuses ? ["current_timberline_jtd_cost"] : [],
                  }}
                  data={closeoutRequestItems}
                />
                {permissions.can_manage_bonuses && (
                  <Box ml={2}>
                    <Typography2 type="metadata">
                      <i>* Only users with with permission to manage bonuses can see these columns.</i>
                    </Typography2>
                  </Box>
                )}
              </PaperPanel.Body>
              {!closeoutRequest.is_closed && (
                <>
                  <Box mb={2} />
                  <PaperPanel.Header isLoading={projectsForCloseoutQuery.isFetching}>
                    <PaperPanel.Header.Title>Projects Eligible For Closeout</PaperPanel.Header.Title>
                    <PaperPanel.Header.Actions>
                      <PaperPanel.Header.Action>
                        <PaperPanel.Header.HelpButton title="Projects Eligible For Closeout Help">
                          <>
                            <strong>I don't see my project?</strong>
                            <p>Projects for closeout are determined by the following criteria:</p>
                            <ol>
                              <li>Is the project closed?</li>
                              <li>
                                Does an account owned by you have a bonus percent for the project (Project &rarr;
                                Closeouts &rarr; Bonus Closeout Request)?
                              </li>
                              <li>Does this account already have a closeout for the project?</li>
                            </ol>
                          </>
                        </PaperPanel.Header.HelpButton>
                      </PaperPanel.Header.Action>
                      <PaperPanel.Header.Action border>
                        <PaperPanel.Header.RefreshButton
                          isFetching={projectsForCloseoutQuery.isFetching}
                          onClick={() => projectsForCloseoutQuery.refetch()}
                        />
                      </PaperPanel.Header.Action>
                    </PaperPanel.Header.Actions>
                  </PaperPanel.Header>
                  <PaperPanel.Body>
                    <ReactTableMui
                      size="small"
                      className={classnames(
                        novoClasses.stripedTable,
                        novoClasses.smallTable,
                        novoClasses.boldHeaderTable
                      )}
                      rightAlignColumns={[
                        "jtd_work_billed",
                        "jtd_cost",
                        "gross_profit",
                        "gross_profit_percent",
                        "sf_gross_receipts_tax",
                        "overhead_and_insurance",
                        "net_profit",
                        "bonus_percent",
                        "bonus_estimate",
                      ]}
                      getHeaderProps={(column) => {
                        if (["add"].includes(column.id)) {
                          return {
                            style: {
                              width: "20px",
                            },
                          };
                        }
                      }}
                      getCellProps={(cell) => {
                        if (["timberline_job"].includes(cell.column.id)) {
                          return {
                            style: {
                              whiteSpace: "nowrap",
                            },
                          };
                        }
                      }}
                      columns={[
                        {
                          Header: "",
                          accessor: "id",
                          id: "add",
                          disableSortBy: true,
                          Cell: ({value, row}) => {
                            return (
                              <Tooltip title="Add Job To Closeout">
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  fixedWidth
                                  onClick={() => {
                                    setSelectedProjectToBeClosed(row.original);
                                    setShowCreateCloseoutRequestItemDialogForm(true);
                                  }}
                                  style={{cursor: "pointer"}}
                                />
                              </Tooltip>
                            );
                          },
                        },
                        {
                          Header: "Job #",
                          accessor: "timberline_job",

                          Cell: ({value, row}) => {
                            return (
                              <Link
                                href={`/v2/projects/${row.original.project_id}/bonuses/closeout-requests/`}
                                underline="always"
                              >
                                {value}
                              </Link>
                            );
                          },
                        },
                        {
                          Header: "Name",
                          accessor: "name",
                          Cell: ({value, row}) => {
                            return (
                              <Link
                                href={`/v2/projects/${row.original.project_id}/bonuses/closeout-requests/`}
                                underline="always"
                              >
                                {value}
                              </Link>
                            );
                          },
                        },
                        {
                          Header: "Final Billing",
                          accessor: "jtd_work_billed",
                          Cell: ({value, row}) => <Currency number={value} />,
                        },
                        {
                          Header: "JTD Cost",
                          accessor: "jtd_cost",
                          Cell: ({value, row}) => <Currency number={value} />,
                        },
                        {
                          Header: "Gross Profit",
                          accessor: "gross_profit",
                          Cell: ({value, row}) => <Currency number={value} />,
                        },
                        {
                          Header: "Profit %",
                          accessor: "gross_profit_percent",
                          Cell: ({value, row}) => <Percentage number={value} precision={2} />,
                        },
                        {
                          Header: "SF Tax",
                          accessor: "sf_gross_receipts_tax",
                          Cell: ({value, row}) => <Currency number={value} />,
                        },
                        {
                          Header: "Overhead & Insurance",
                          accessor: "overhead_and_insurance",
                          Cell: ({value, row}) => <Currency number={value} />,
                        },
                        {
                          Header: "Profit",
                          accessor: "net_profit",
                          Cell: ({value, row}) => <Currency number={value} />,
                        },
                        {
                          Header: "Bonus %",
                          accessor: "bonus_percent",
                          Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                        },
                        {
                          Header: "Bonus Estimate",
                          id: "bonus_estimate",
                          // accessor: "net_profit_percent",
                          Cell: ({value, row}) => {
                            const bonusAmount = (row.original.bonus_percent / 100) * row.original.net_profit;
                            return <Currency number={bonusAmount} />;
                          },
                        },
                      ]}
                      initialState={{
                        // sortBy: [
                        //   {
                        //     id: "timberline_job",
                        //     desc: false,
                        //   },
                        // ],
                        hiddenColumns: closeoutRequest.is_closed ? ["add"] : [],
                      }}
                      data={projectsForCloseout}
                    />
                  </PaperPanel.Body>
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="logs">
            <Box mx={-3} mb={-3} mt={-4}>
              {/* <FSMLog
                url={`/bonuses/accounts/${bonusAccountId}/distribution-requests/${distributionRequestId}/state-log/`}
              /> */}
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <BonusAccountCloseoutRequestDialogForm
        isOpen={showUpdateCloseoutRequestDialog}
        isReadOnly={closeoutRequest.is_closed}
        handleClose={() => {
          setShowUpdateCloseoutRequestDialog(false);
        }}
        initialValues={closeoutRequest}
        // onDelete={(values, form) => {
        //   // setShowDeleteCloseoutRequestDialog(true);
        // }}
        onSubmit={(values, form) => {
          blockUI.blockUI("Creating...");
          updateCloseoutRequest.mutateAsync(values).then((newCloseoutRequest) => {
            blockUI.unblockUI();
            setShowUpdateCloseoutRequestDialog(false);
            closeoutRequestQuery.refetch();
          });
        }}
        onDelete={() => {
          blockUI.blockUI("Deleting...");
          setShowUpdateCloseoutRequestDialog(false);
          deleteCloseoutRequest.mutateAsync().then(() => {
            navigate(`/v2/bonuses/accounts/${bonusAccountId}/#tab=closeoutRequests`);
            blockUI.unblockUI();
          });
        }}
      />

      <BonusAccountCloseoutRequestItemDialogForm
        bonusAccountId={bonusAccountId}
        closeoutRequestId={closeoutRequestId}
        isNew={true}
        isOpen={showCreateCloseoutRequestItemDialogForm}
        handleClose={() => setShowCreateCloseoutRequestItemDialogForm(false)}
        initialValues={{
          ...selectedProjectToBeClosed,
          // bonus_percent: projectBonusPercents[selectedProjectToBeClosed.project_id] || 0,
          cost_adjustments: 0,
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createCloseoutRequestItem.mutateAsync(values).then((newCloseoutRequest) => {
            Promise.all([
              projectsForCloseoutQuery.refetch(),
              closeoutRequestQuery.refetch(),
              closeoutRequestItemsQuery.refetch(),
            ]).then(() => {
              blockUI.unblockUI();
              setShowCreateCloseoutRequestItemDialogForm(false);
            });
          });
        }}
        defaultSFGrossReceiptsTaxRate={pageData.defaultSFGrossReceiptsTaxRate}
        defaultOverheadAndInsuranceRate={pageData.defaultOverheadAndInsuranceRate}
      />

      <BonusAccountCloseoutRequestItemDialogForm
        bonusAccountId={bonusAccountId}
        closeoutRequestId={closeoutRequestId}
        closeoutRequestItemId={selectedCloseoutRequestItem.id}
        isOpen={showUpdateCloseoutRequestItemDialogForm}
        isReadOnly={closeoutRequest.is_closed}
        showAuditLink={permissions.can_manage_bonuses}
        handleClose={() => setShowUpdateCloseoutRequestItemDialogForm(false)}
        initialValues={selectedCloseoutRequestItem}
        onSubmit={(values) => {
          blockUI.blockUI("Updating...");
          updateCloseoutRequestItem.mutateAsync(values).then((newCloseoutRequest) => {
            Promise.all([projectsForCloseoutQuery.refetch(), closeoutRequestQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowUpdateCloseoutRequestItemDialogForm(false);
            });
          });
        }}
        onDelete={(values) => {
          blockUI.blockUI("Deleting...");
          deleteCloseoutRequestItem.mutateAsync(values.id).then((newCloseoutRequest) => {
            Promise.all([projectsForCloseoutQuery.refetch(), closeoutRequestQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowUpdateCloseoutRequestItemDialogForm(false);
            });
          });
        }}
      />

      <ConfirmationDialog
        isOpen={showSubmitDialog}
        onApprove={() => {
          blockUI.blockUI("Submitting...");
          closeoutRequestRPC.mutateAsync({action: "submit"}).then(() => {
            Promise.all([projectsForCloseoutQuery.refetch(), closeoutRequestQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowSubmitDialog(false);
            });
          });
        }}
        onDeny={() => {
          setShowSubmitDialog(false);
        }}
      >
        You would like to submit this closeout request for <Currency number={closeoutRequest.bonus_amount} />?
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={showManagementApproveDialog}
        onApprove={() => {
          blockUI.blockUI("Approving...");
          closeoutRequestRPC.mutateAsync({action: "management-approve"}).then(() => {
            Promise.all([pageDataQuery.refetch(), closeoutRequestQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowManagementApproveDialog(false);
            });
          });
        }}
        onDeny={() => {
          setShowManagementApproveDialog(false);
        }}
      >
        You would like to approve this closeout request for <Currency number={closeoutRequest.bonus_amount} />? Final
        approval will be required from the account holder {closeoutRequest?.account?.user?.full_name}.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={showFinalApproveDialog}
        onApprove={() => {
          blockUI.blockUI("Approving...");
          closeoutRequestRPC.mutateAsync({action: "approve"}).then(() => {
            Promise.all([pageDataQuery.refetch(), closeoutRequestQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowFinalApproveDialog(false);
            });
          });
        }}
        onDeny={() => {
          setShowFinalApproveDialog(false);
        }}
      >
        You would like to approve this closeout request for <Currency number={closeoutRequest.bonus_amount} />? This
        will create a credit to the account.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={showRejectDialog}
        onApprove={() => {
          blockUI.blockUI("Rejecting...");
          closeoutRequestRPC.mutateAsync({action: "reject"}).then(() => {
            Promise.all([pageDataQuery.refetch(), closeoutRequestQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowRejectDialog(false);
            });
          });
        }}
        onDeny={() => {
          setShowRejectDialog(false);
        }}
      >
        You would like to reject this closeout request for <Currency number={closeoutRequest.bonus_amount} />?
      </ConfirmationDialog>
      {/*
      <pre>{JSON.stringify(closeoutRequest, null, 2)}</pre>
      <pre>{JSON.stringify(bonusAccount, null, 2)}</pre>
      <pre>{JSON.stringify(selectedProjectToBeClosed, null, 2)}</pre>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <pre>{JSON.stringify(projectBonusPercents, null, 2)}</pre>
      <pre>{JSON.stringify(closeoutRequestQuery, null, 2)}</pre>
      <pre>{JSON.stringify(closeoutRequestItemsQuery, null, 2)}</pre>
      */}
    </>
  );
};

export default BonusAccountCloseoutRequestDetail;
